import { useState } from 'react';

export default function SearchGroup(props) {
  const [searchString, setSearchString] = useState('');

  const handleSearchState = e => setSearchString(e.target.value);

  const onSubmit = e => {
    e.preventDefault();
    props.searchHandler(searchString.trim());
    searchString.trim() || setSearchString(''); // clean empty spaces after search
  };

  return (
    <form onSubmit={onSubmit}>
      <input
        className='search__input'
        type='text'
        value={searchString}
        onChange={handleSearchState}
      />
      <button className='search__button'>
        <span className='search__arrow-icon'></span>Искать
      </button>
    </form>
  );
}
