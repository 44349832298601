// TODO: set searchData as a context

import Header from './Header';
import SearchGroup from './SearchGroup';
import SearchResults from './SearchResults';

import * as rawData from '../data/data.json';

import { useState } from 'react';

export default function Search(props) {
  const plays = rawData.default.result;
  const [searchData, setSearchData] = useState({
    searchString: '',
    searchResults: [],
  });

  const searchHandler = searchString => {
    if (!searchString) {
      return setSearchData({
        searchString: '',
        searchResults: [],
      });
    }
    const filteredPlays = plays.reduce(
      (acc, curr) => {
        if (
          curr.author_firstName
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          curr.author_lastName
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          acc.authors.some(
            el => el === `${curr.author_lastName} ${curr.author_firstName}`
          ) ||
            acc.authors.push(
              `${curr.author_lastName} ${curr.author_firstName}`
            );
        }
        if (curr.title.toLowerCase().includes(searchString.toLowerCase())) {
          acc.plays.push(curr);
        }
        return acc;
      },
      {
        plays: [],
        authors: [],
      }
    );
    setSearchData({
      searchString,
      searchResults:
        (filteredPlays.plays.length > 0 || filteredPlays.authors.length > 0) ? filteredPlays : [],
    });
  };

  return (
    <>
      <Header searchData={searchData} />
      <SearchGroup searchHandler={searchHandler} />
      {searchData.searchResults.length !== 0 && (
        <SearchResults searchResults={searchData.searchResults} />
      )}
    </>
  );
}
