import './styles/styles.css';

import Search from './components/Search';

function App() {
  return (
    <div className='app'>
      <Search />
    </div>
  );
}

export default App;
