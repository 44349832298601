export default function PlayCard({ play }) {
  return (
    <li className='play'>
      <h3 className='play__cover'>{play.title}</h3>
      <div className='play__meta'>
        <p className='play__author'>{`${play.author_lastName} ${play.author_firstName}`}</p>
        <p className='play__city'>{play.city}</p>
        <p className='play__year'>{play.year}</p>
      </div>
    </li>
  );
}
