export default function AuthorsList({ authorsKey, authorsArray }) {
  return (
    <>
      <h2 className='authors__letter'>{authorsKey}</h2>
      <ul className='authors__names'>
        {authorsArray.map((author, i) => (
          <li className='authors__name' key={i}>
            {author}
          </li>
        ))}
      </ul>
    </>
  );
}
