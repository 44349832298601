import SearchResultsPlays from './SearchResultsPlays';
import SearchResultsAuthors from './SearchResultsAuthors';

export default function SearchResults({ searchResults }) {
  return (
    <div className='search__results'>
      {searchResults?.plays && (
        <SearchResultsPlays plays={searchResults.plays} />
      )}
      {searchResults?.authors && (
        <SearchResultsAuthors authors={searchResults.authors.sort()} />
      )}
    </div>
  );
}
