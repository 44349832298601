export default function Header({ searchData }) {
  const renderHeader = searchData => {
    if (searchData.searchResults.length === 0 && !searchData.searchString) {
      return 'Поиск';
    }
    if (searchData.searchResults.length === 0) {
      return `По запросу «${searchData.searchString}» мы ничего не нашли`;
    }
    return `По запросу «${searchData.searchString}» мы нашли:`;
  };

  return (
    <header className='search__header'>
      <h1 className='search__header-text'>{renderHeader(searchData)}</h1>
    </header>
  );
}
