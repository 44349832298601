import PlayCard from './PlayCard';

export default function SearchResultsPlays({ plays }) {
  return (
    <ul className='search__results-plays'>
      {plays
        .sort((a, b) => (a.title > b.title ? 1 : -1))
        .map(play => (
          <PlayCard key={play._id} play={play} />
        ))}
    </ul>
  );
}
