import AuthorsList from './AuthorsList';

export default function SearchResultsAuthors({ authors }) {
  const authorsObject = authors.reduce((acc, cur) => {
    const firstLetter = cur.charAt(0);
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(cur);
    return acc;
  }, {});

  return (
    <ul className='search__results-authors'>
      {authors &&
        Object.keys(authorsObject).map((key, i) => (
          <li key={i} className='authors__block'>
            <AuthorsList authorsKey={key} authorsArray={authorsObject[key]} />
          </li>
        ))}
    </ul>
  );
}
